import { useSelector } from 'react-redux';
import Input from './inputs/input';

const Form = ({ fields, fieldsExist }) => {
  const isDirty = useSelector((state) => state.orgInformation.isDirty);
  return (
    <>
      {Object.keys(fields).map((key) => (
        <Input data={fields[key]} key={key} />
      ))}
      {fieldsExist && (
        <input
          type="submit"
          disabled={!isDirty}
          className={`sw-no-unsaved-changes ml-0 inline-block transition duration-300 uppercase bg-red text-white button-solid hover:opacity-80`}
          value="submit"
          style={!isDirty ? { opacity: 0.3 } : {}}
        />
      )}
    </>
  );
};

export default Form;
