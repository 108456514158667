import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import { setLoading } from '../features/providers/generic';
import Input from './inputs/input';
import axios from '../utilities/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Bars } from 'react-loader-spinner';
import { useState } from 'react';
import Form from './Form';

const Signup = () => {
  const [redirecting, setRedirecting] = useState(false);

  const loading = useSelector(
    (state) => state.orgInformation.isLoading || state.generic.loading,
  );

  const Fields = useSelector((state) => state.orgInformation.data.fields);

  const loaderStyle = {
    minHeight: '30vh',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  };

  const dispatch = useDispatch();

  const fieldsExist = Object.keys(Fields).length > 0;
  const submit = async (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const failedToConfirm = Fields.reduce((carry, fieldSingle) => {
      const { type, value } = fieldSingle;
      if ('checkbox_must_agree' === type) {
        return carry || !value;
      }

      return false;
    }, false);
    if (failedToConfirm) {
      alert('Please agree to Certification');
      return;
    }

    try {
      dispatch(setLoading(true));
      const request = await axios.post('/organization', {
        fields: Fields,
      });

      setRedirecting(true);

      // redirect to request.data.data.redirect
      const redirect = request?.data?.data?.redirect;
      if (redirect) {
        toast('Successfully updated, redirecting');
        window.location.href = request.data.data.redirect;
        return;
      }
      toast('Successfully updated');
    } catch (e) {
      toast('Error!' + e.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formElementsWrapStyle = {
    zIndex: 1,

    transition: 'opacity 0.3s ease-in-out',
    position: 'relative',
  };
  if (!fieldsExist || loading || redirecting) {
    return (
      <div style={loaderStyle}>
        <Bars
          height="80"
          width="80"
          radius="9"
          color="#011C50"
          ariaLabel="three-dots-loading"
          wrapperStyle
          wrapperClass
        />
      </div>
    );
  }
  return (
    <>
      <form className="container mx-auto flex justify-center" onSubmit={submit}>
        <div style={formElementsWrapStyle}>
          <Form fields={Fields} fieldsExist={fieldsExist} />
        </div>
      </form>
    </>
  );
};

export default Signup;
